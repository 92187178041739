<template>
  <div style="margin-bottom: -2rem">
    <div
      class="__side_nav"
      style="padding: 0 !important; margin-bottom: -2rem !important">
      <router-link class="btn" :to="'/admin/users/userSegments/GoldUsers'">
        Gold <span v-if="goldUsersCount">({{ goldUsersCount }})</span>
      </router-link>
      <router-link class="btn" :to="'/admin/users/userSegments/SilverUsers'">
        Silver <span v-if="silverUsersCount">({{ silverUsersCount }})</span>
      </router-link>
      <router-link class="btn" :to="'/admin/users/userSegments/BronzeUsers'">
        Bronze <span v-if="bronzeUsersCount">({{ bronzeUsersCount }})</span>
      </router-link>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";

  export default {
    name: "user-segments",
    data() {
      return {
        total: "",
      };
    },

    computed: {
      goldUsersCount() {
        return store.getters["admin/users/goldUsersTotal"];
      },
      silverUsersCount() {
        return store.getters["admin/users/silverUsersTotal"];
      },
      bronzeUsersCount() {
        return store.getters["admin/users/bronzeUsersTotal"];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .__side_nav {
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .router-link-active {
    background-color: #fbf1e5 !important;
    font-weight: 500;
    color: #f7921c !important;
    border-radius: 6px;
    border: 1px solid #f7921c !important;
  }
</style>
