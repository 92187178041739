import store from '@/state/store.js';
import MainUserComponent from './mainsUsersComponent';
import userSegments from './userSegments.vue';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Individual Users',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'individual-users',
  components: {
    MainUserComponent,
    userSegments
  },
  data() {
    return {
      parent: {
        name: 'individual',
        storeDataName: 'individualUsers',
        dispatchName: 'admin/users/getIndividualUsers',
        searchDispatchName: 'admin/users/searchIndividualUsers'
      }
    };
  },
  methods: {},
  computed: {},
  mounted() {
    // store.dispatch('admin/users/getIndividualUsers')
  }
};